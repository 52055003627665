import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

export const Technologies = () => {
  return (
    <div  style={{ background: "linear-gradient(white, #fdf2ec)", minHeight:700,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundRepeat: "no-repeat",
      backgroundImage: "url('/assets/data-management/enagemebnt-models-bg-right.png')",
      backgroundSize: "cover",
      backgroundPosition: "right center", // Flips the background to right
    }} >
    <Container
    //   fluid
      id='ourTechnologies'
      className="py-4"
     
    >
      <h2 className="text-center mb-4 primary-text-color  fw-bold title-text">
        Our Technologies
      </h2>

      {/* Informatica Data Management Section */}
      <Section
        title="INFORMATICA DATA MANAGEMENT"
        items={["Integration", "Data Governance", "Master Data Management"]}
      />

      {/* AI Section */}
      <Section title="AI" items={["Predictive Analytics", "Generative AI"]} />

      {/* Business Intelligence Section */}
      <Section
        title="BUSINESS INTELLIGENCE"
        items={[
          "Data Warehouse",
          "Data Lakes",
          "Data Modeling",
          "Visualization",
          "Reporting",
        ]}
      />
    </Container>
    </div>

  );
};

const Section = ({ title, items }) => (
  <Row className="mb-4 g-5">
    <Col xs={12} sm={5} md={4} style={{ display:'flex',justifyContent:'center',alignItems:'center' }} >
      <h3
        // className="text-left"
        style={{ color: "#000", fontSize: 24,fontWeight:700,textAlign:'center' }}
      >
        {title}
      </h3>
    </Col>
    <Col xs={12} sm={7} md={8}>
      <Row className="mb-4 g-5">
        {items.map((item, index) => (
          <Col xs={12} sm={6} md={4} lg={3} key={index}>
            <Card className="h-100 text-center" style={cardStyles}>
              <Card.Body style={{ lineHeight:1.3,height:60 }} >{item}</Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Col>

    {/* <hr style={{ borderTop: "1px solid #e0e0e0" }} /> */}
  </Row>
);

const cardStyles = {
  background: "rgba(255, 236, 224, 1)",
  border: 'none',
  borderRadius: "8px",
  boxShadow: "0px 4px 15.6px 0px rgba(0, 0, 0, 0.25)",
  fontSize: "14px",
  color: "#000",
  padding: "5px 10px",
};
