import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';

export const DataManagementServices = () => {
    const [isMobile, setIsMobile] = useState(false);

    const services = [
        {
          id: 1,
          title: "Data Governance:",
          description:
            "Establish a structured framework for decision-making, ensuring that data is managed in alignment with your business objectives and compliance needs.",
          imageSrc: "/assets/data-management/data-management-services/card-item-1.png",
        },
        {
          id: 2,
          title: "Data Integration:",
          description:
            "Seamlessly integrate data from multiple sources to provide a unified view, supporting informed decisions and smoother operations. Our data integration solutions ensure compatibility and consistency across all platforms, creating a cohesive data ecosystem.",
            imageSrc: "/assets/data-management/data-management-services/card-item-2.png",
        },
        {
          id: 3,
          title: "Master Data Management (MDM):",
          description:
            "Create a single source of truth across your organization. Our MDM solutions eliminate redundancies and provide accurate, up-to-date data for enhanced operational efficiency.",
            imageSrc: "/assets/data-management/data-management-services/card-item-3.png",
        }
      ];
      
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Set breakpoint for mobile
      };
  
      handleResize(); // Check initial screen size
      window.addEventListener("resize", handleResize); // Add event listener
  
      return () => {
        window.removeEventListener("resize", handleResize); // Clean up listener
      };
    }, []);
  
    return (
      <div
        className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8 text-white"
        style={{
          color: "white",
          paddingBottom: "60px",
          position: "relative",
          overflow: "hidden",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: "url('/assets/data-management/banner.png')",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "40px 0",
          minHeight: 643,
        }}
      >
        <Container style={{ maxWidth:1141 }} >
          <h2 className="primary-text-color fw-bold text-center title-text">
          Comprehensive Data Management Services
          </h2>
          <p 
            style={{
              width:'100%',
              display:'flex',
              justifyContent:'center',
            }}
          >
        <p
        className="text-center text-lg text-gray-300 mb-12 font-family"
          style={{
            fontSize:16,
            maxWidth:840
          }}
        >
        At ZCLAP, our data management services are structured to offer end-to-end solutions for organizations seeking excellence in data integrity, compliance, and accessibility.
        </p>
          </p>
          {isMobile ? (
            <div 
              style={{
                display:'flex',
                justifyContent:'center'
              }}
            >
                 <Carousel
                  pause='hover'
                  indicators={false}
                  controls={false}
                 >
              {services.map((service) => (
                <Carousel.Item key={service.id}>
                 <ItemCard service={service} />
                </Carousel.Item>
              ))}
            </Carousel>
            </div>
         
          ) : (
            <Row className="g-4">
              {services.map((service) => (
                <Col
                  key={service.id}
                  xs={12}
                  sm={6}
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <ItemCard service={service} />
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </div>
    );
}


const ItemCard=({ service })=>{
  return    <Card
  style={{
    width: "18rem",
    border: "none",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderTopLeftRadius: 80,
    padding: 12,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 80,
    minWidth:320,
  }}
>
  <Card.Img
    variant="top"
    src={service.imageSrc}
    alt="AI Advisory"
    style={{
      borderTopLeftRadius: 80,
      borderTopRightRadius: 0,
    }}
  />
  <Card.Body
    style={{
      padding: "1.5rem .5rem",
      textAlign: "left",
      
    }}
  >
    <Card.Title
     className="font-family"
      style={{
        fontWeight: "bold",
        fontSize: "1.2rem",
        minHeight:60
      }}
    >
      {service.title}
    </Card.Title>
    <Card.Text
     className="font-family"
      style={{
        fontSize: 16,
        color: "#555",
        minHeight:200
      }}
    >
      {service.description}
    </Card.Text>
  </Card.Body>
</Card>
}