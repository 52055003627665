import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";



import { Home } from "./pages/home";
import { DataManagement } from "./pages/data-management";
import { Button } from "react-bootstrap";

const AppRoutes = () => {
  const location = useLocation();
  const HEADER_HEIGHT = 120; // Replace with your header's height in pixels

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - HEADER_HEIGHT;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [location]);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home />
        }
      />
      <Route path="/data-management" element={<DataManagement />} />
    </Routes>
  );
};

function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled near the bottom
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      // Calculate 25% of the scrollable height
      const scrollableHeight = scrollHeight - clientHeight;
      const threshold = scrollableHeight * 0.25;

      if (scrollTop >= threshold) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Router>
      <div className="App" style={{ overflow: 'hidden' }} >
        <AppRoutes />
      </div>
      {showButton && (
        <Button className="go-to-top" onClick={scrollToTop}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="#fff"
          >
            <path d="M12 2c.39 0 .78.15 1.06.44l6 6a1.5 1.5 0 11-2.12 2.12L13 6.12V21a1.5 1.5 0 01-3 0V6.12L7.06 10.56a1.5 1.5 0 11-2.12-2.12l6-6A1.5 1.5 0 0112 2z" />
          </svg>

        </Button>
      )}
    </Router>
  );
}

export default App;
