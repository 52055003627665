import React, { useState } from "react";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import "./tabs-component.scss";

export const TabComponent = ({
  title,
  subtitle,
  tabsData,
  styleConfig,
  placement = "left",
  styleTitle={}
}) => {
  const [key, setKey] = useState(tabsData[0].key);

  const getNavItemStyle = (tabKey) => {
    const isActive = key === tabKey;
  
    if (placement === "left") {
      return {
        background: isActive
          ? "linear-gradient(93.01deg, #F06A30 11.72%, #8A3D1C 118.94%)"
          : "rgba(216, 216, 216, 1)", // Fallback for non-active
        color: isActive ? "#fff" : "#000",
      };
    } else {
      return {
        backgroundColor: isActive
          ? "rgba(255, 240, 229, 1)"
          : "transparent",
        color: isActive ? "#000" : "#000",
        zIndex: isActive ? 2 : 1,
      };
    }
  };
  

  return (
    <div
      className="tab-container"
      style={{ backgroundColor: "#f8f9fa",...styleConfig?.tabContainerStyle,minHeight:600,display:'flex',justifyContent:'center',alignItems:'center',
        backgroundRepeat: "no-repeat",
        backgroundImage: placement === 'left'?"none": "url('/assets/data-management/enagemebnt-models-bg.png')",
        backgroundSize: "cover",
        backgroundPosition: "right center", // Flips the background to right
       }}
    >
      <Container>
        <h2
          className="tab-title primary-text-color title-text fw-bold"
          style={styleTitle}
        >
          {title}
        </h2>
        {subtitle && (
          <p
            className="tab-subtitle"
            style={{ color: styleConfig?.subtitleColor || "#6c757d" }}
          >
            {subtitle}
          </p>
        )}
        <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
          {placement === "left" ? (
            <Row className="equal-height">
              <Col md={3} sm={12} className="left-menu">
                <Nav variant="pills" className="flex-column">
                  {tabsData.map((tab) => (
                    <Nav.Item key={tab.key}>
                      <Nav.Link
                        eventKey={tab.key}
                        style={getNavItemStyle(tab.key)}
                        role="tab"
                        aria-selected={key === tab.key}
                        className="nav-link"
                      >
                        {tab.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col md={9} sm={12} className="right-content">
                <Tab.Content className="tab-content">
                  {tabsData.map((tab) => (
                    <Tab.Pane
                      key={tab.key}
                      eventKey={tab.key}
                      role="tabpanel"
                      className={`tab-pane ${key === tab.key ? "active" : ""}`}
                    >
                      <Row>
                        <Col lg={tab.imageSrc ? 8 : 12} md={12}>
                          <p className="tab-description">{tab.description}</p>
                        </Col>
                        {tab.imageSrc && (
                          <Col
                            lg={4}
                            md={12}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <div style={{ height: 200, width: 300, padding: 10 }}>
                              <img
                                src={tab.imageSrc}
                                alt={tab.title}
                                className="tab-image"
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          ) : (
            <div className="top-tabs">
              <Nav variant="pills" className="nav-pills">
                {tabsData.map((tab) => (
                  <Nav.Item key={tab.key}>
                    <Nav.Link
                      eventKey={tab.key}
                      style={getNavItemStyle(tab.key)}
                      role="tab"
                      aria-selected={key === tab.key}
                      className="nav-link"
                    >
                              <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="11.4888"
                          cy="12"
                          r="11.0226"
                          fill="#152C5B"
                        />
                        <path
                          d="M7.35535 12.6889L10.6621 14.7556L15.6223 9.24435"
                          stroke="white"
                          strokeWidth="1.14819"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span style={{ marginLeft:10 }} >{tab.title}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="tab-content">
                {tabsData.map((tab) => (
                  <Tab.Pane
                    key={tab.key}
                    eventKey={tab.key}
                    style={{...getNavItemStyle(tab.key),padding:'20px 10px'}}
                    role="tabpanel"
                    className={`tab-pane ${key === tab.key ? "active" : ""}`}
                  >
                    <Row>
                      <Col lg={tab.imageSrc ? 8 : 12} md={12}>
                        <p className="tab-description">{tab.description}</p>
                      </Col>
                      {tab.imageSrc && (
                        <Col lg={4} md={12}>
                          <img
                            src={tab.imageSrc}
                            alt={tab.title}
                            className="tab-image"
                          />
                        </Col>
                      )}
                    </Row>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </div>
          )}
        </Tab.Container>
      </Container>
    </div>
  );
};
