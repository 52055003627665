import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { countries } from "../../config/countries";

export const ContactForm = ({ title, description, buttonText }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    country: "",
    email: "",
    phone: "",
    projectDetails: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const googleFormEndpoint =
      "https://docs.google.com/forms/d/e/1FAIpQLSdpUcatQsjpu54BglAw_dFJ9C4i_rXwHN-7VMEy6OpsgBV1tA/formResponse";

    const googleFormPayload = {
      "entry.739630584": formData.fullName,
      "entry.1603457925": formData.email,
      "entry.1720066199": formData.phone,
      "entry.1172919266": formData.projectDetails,
      "entry.1678180398": formData.country,
    };

    try {
     await fetch(googleFormEndpoint, {
        method: "POST",
        mode: "no-cors",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(googleFormPayload),
      });
      alert("Form submitted successfully!");
      setFormData({
        fullName: "",
        country: "",
        email: "",
        phone: "",
        projectDetails: "",
      });
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("Error submitting the form.");
    }
  };

  return (
    <Container style={{ maxWidth:1000 }} className="contact-form py-5" id='contact'>
      <h2 className="fw-bold title-text font-family">
        {title || "We'd love to hear from you anytime"}
      </h2>
      <p
        className="text-lg text-gray-300 mb-12 font-family"
        style={{
          fontSize: 24,
          color: "#000",
          marginBottom: 20,
        }}
      >
        {description ||
          "Feel free to reach out to us by filling out the form below."}
      </p>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} className="mb-4">
            <Form.Group controlId="formFullName">
              <Form.Label className="font-family" >Full Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
                className="font-family"
              />
            </Form.Group>
          </Col>

          <Col md={6} className="mb-4">
            <Form.Group controlId="formCountry">
              <Form.Label className="font-family" >Select Country Name</Form.Label>
              <Form.Select
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
                className="font-family"
              >
                <option value="">Select your country</option>
                {countries?.map((country) => (
                  <option className="font-family" key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={6} className="mb-4">
            <Form.Group controlId="formEmail">
              <Form.Label className="font-family" >Email*</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="font-family"
              />
            </Form.Group>
          </Col>

          <Col md={6} className="mb-4">
            <Form.Group controlId="formPhone">
              <Form.Label className="font-family" >Phone*</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter your phone number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="font-family"
              />
            </Form.Group>
          </Col>

          <Col xs={12} className="mb-4">
            <Form.Group controlId="formProjectDetails">
              <Form.Label className="font-family" >Tell Us About Your Project*</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Provide a brief description"
                name="projectDetails"
                value={formData.projectDetails}
                onChange={handleChange}
                required
                className="font-family"
              />
            </Form.Group>
          </Col>
        </Row>

        <div  className="text-left">
          <Button size="sm" variant="default" type="submit" 
          className="font-family"
            style={{
              border:' 1px solid rgba(0, 0, 0, 1)',
              width:164
            }}
          >
            {buttonText || "Submit"}
          </Button>
        </div>
      </Form>
    </Container>
  );
};
