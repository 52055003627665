import React from "react";
import { TabComponent } from "../../components/tabs-component";

export const ValuesTab = () => {
  const tabs = [
    {
      key: "customized",
      title: "Customized Precision",
      description: (
        <p
          style={{
            fontSize: 18,
            fontWeight: 400,
          }}
        >
          We deliver AI solutions uniquely tailored to your business needs.
        </p>
      ),
      imageSrc: "/assets/ai-values/values-tab.png",
    },
    {
      key: "collaboration",
      title: "Transparent Collaboration",
      description: (
        <p
          style={{
            fontSize: 18,
            fontWeight: 400,
          }}
        >
       Our real-time reporting and insights keep you informed and involved every step of the way.
        </p>
      ),
      imageSrc: "/assets/ai-values/values-tab.png",
    },
    {
      key: "scalability",
      title: "Scalability for Growth",
      description: (
        <p
          style={{
            fontSize: 18,
            fontWeight: 400,
          }}
        >
         Our solutions are designed to grow with you, adapting to new challenges and opportunities.
        </p>
      ),
      imageSrc: "/assets/ai-values/values-tab.png",
    },
    {
      key: "innovation",
      title: "Innovative Edge",
      description: (
        <p
          style={{
            fontSize: 18,
            fontWeight: 400,
          }}
        >
        We leverage cutting-edge AI advancements to ensure your business remains competitive.
        </p>
      ),
      imageSrc: "/assets/ai-values/values-tab.png",
    },
  ];

  return (
    <div id="values">
      <TabComponent
        title="The Values We Bring with Our AI Solutions"
        subtitle="Our AI solutions are grounded in key values that set us apart."
        tabsData={tabs}
      />
    </div>
  );
};
