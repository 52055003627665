import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

export const HeroSection = () => {
  return (
    <section
      style={{
        color: "white",
        paddingBottom: "60px",
        position: "relative",
        overflow: "hidden",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url('/assets/hero-section-right.png')",
        height: 643,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
      }}
    >
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <h1
              className="primary-text-color fw-bold title-text">
              Tailored AI Solutions for Real Business Impact
            </h1>
            <p
            className="font-family"
            style={{
              fontSize:24
            }}
            >
              At ZCLAP, we design AI solutions that align with your business
              goals and drive results. From strategy to support, we're here to
              transform your AI journey.
            </p>
            <Button
               className="font-family primary-bg"
              //  variant="default"
              size="lg"
              href="#contact"
              style={{
                color: "white",
                borderRadius:4
              }}
            >
              Book a free consultation to get started!
              <span style={{ marginLeft: 10 }}>
            <svg
              width="18"
              height="12"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.0376 2.0448L9.86865 1.46306C10.2205 1.21674 10.7895 1.21674 11.1377 1.46306L18.415 6.55458C18.7669 6.8009 18.7669 7.19921 18.415 7.44291L11.1377 12.5371C10.7858 12.7834 10.2168 12.7834 9.86865 12.5371L9.0376 11.9553C8.68197 11.7064 8.68945 11.3002 9.05257 11.0565L13.5635 8.04824H2.80469C2.3068 8.04824 1.90625 7.76785 1.90625 7.41933V6.58079C1.90625 6.23227 2.3068 5.95188 2.80469 5.95188H13.5635L9.05257 2.94361C8.68571 2.69991 8.67822 2.29374 9.0376 2.0448Z"
                fill="white"
              />
            </svg>
            </span>
            </Button>
          </Col>
          <Col md={6}>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",

                borderRadius: "10px",
              }}
            ></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
