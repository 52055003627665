/* eslint-disable no-multi-str */
import React, { useState, useEffect } from "react";
import { Card, Row, Col, Container, Carousel } from "react-bootstrap";

export const AIServices = ({ noBackgroundImg=true,extra }) => {
  const [isMobile, setIsMobile] = useState(false);

  const services = [
    {
      id: 1,
      title: "AI Advisory Services:",
      description:
        "Align Strategy with Business Goals: Our advisory team works closely with stakeholders to craft AI strategies that drive real business value. From selecting the right technology to future-proofing your investments, we guide you every step of the way, allowing your team to focus on core business functions.",
      imageSrc: "/assets/ai-services/card-item-1.png",
    },
    {
      id: 2,
      title: "AI Implementation Services:",
      description:"On-Time, On-Budget Deployment: ZCLAP’s experts ensure your AI initiatives are implemented effectively, balancing technology, people, and processes to create sustainable value. We offer full-scale AI deployment, quick-start foundation services, and expert guidance to keep your project on track.",
      imageSrc: "/assets/ai-services/card-item-2.png",
    },
    {
      id: 3,
      title: "Managed Services:",
      description:"Continuous Support and Optimization: Our managed services let your team focus on growth while we manage and optimize your AI systems With ongoing monitoring and future-proofing, We maximize the impact of your technology investments and reduce operational risks.",
      imageSrc: "/assets/ai-services/card-item-3.png",
    }
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set breakpoint for mobile
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize); // Add event listener

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener
    };
  }, []);

  return (
    <div
      id='ai-services'
      className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8 text-white"
      style={{
        color: "white",
        paddingBottom: "60px",
        position: "relative",
        overflow: "hidden",
        ...noBackgroundImg&&({
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: "url('/assets/ai-services/ai-services.png')",
        }),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px 0",
        minHeight: 820,
      }}
    >
      <Container style={{    maxWidth:1141 }} >
        <h2 className="primary-text-color fw-bold text-center title-text">
          Our AI Services
        </h2>
        <p className="text-center text-lg text-gray-300 mb-12 font-family"
          style={{
            fontSize:24,
            color:noBackgroundImg?'#fff':'#000'
          }}
        >
         Comprehensive AI Solutions, Tailored for Every Stage
        </p>
        {isMobile ? (
             <div 
             style={{
               display:'flex',
               justifyContent:'center'
             }}
           >
          <Carousel
          pause='hover'
          indicators={false}
          controls={false}
          >
            {services.map((service) => (
              <Carousel.Item key={service.id}>
                <ItemCard service={service} />
              </Carousel.Item>
            ))}
          </Carousel>
          </div>
        ) : (
          <Row className="g-4">
            {services.map((service) => (
              <Col
                key={service.id}
                xs={12}
                sm={6}
                md={4}
                className="d-flex justify-content-center"
              >
                 <ItemCard service={service} />
              </Col>
            ))}
          </Row>
        )}
        {extra}
      </Container>
    </div>
  );
};
const ItemCard=({ service })=>{
  return    <Card
  style={{
    width: "18rem",
    border: "none",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderTopLeftRadius: 80,
    padding: 12,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 80,
    minWidth:320,
  }}
>
  <Card.Img
    variant="top"
    src={service.imageSrc}
    alt="AI Advisory"
    style={{
      borderTopLeftRadius: 80,
      borderTopRightRadius: 0,
    }}
  />
  <Card.Body
    style={{
      padding: "1.5rem .5rem",
      textAlign: "left",
      
    }}
  >
    <Card.Title
     className="font-family"
      style={{
        fontWeight: "bold",
        fontSize: "1.2rem",
        minHeight:30
      }}
    >
      {service.title}
    </Card.Title>
    <Card.Text
     className="font-family"
      style={{
        fontSize: 16,
        color: "#555",
        minHeight:120
      }}
    >
      {service.description}
    </Card.Text>
  </Card.Body>
</Card>
}