import React from "react";
import { TabComponent } from "../../components/tabs-component";
import { Container } from "react-bootstrap";

export const IndustryUseCases = ({ id='' }) => {
  const industryTabs = [
    {
      key: "healthcare",
      title: "Healthcare",
      description:<div>
        <h4
        className="primary-text-color"
        style={{
          fontSize:24,
          fontWeight:700
        }}
        >Predictive Analysis for Patient Outcomes using AI-driven insights</h4>
        <p
        style={{
          fontSize:18,
          fontWeight:400
        }}
        >
        Utilize AI-driven insights to analyze patient data, forecast health risks, and tailor treatment plans for improved patient outcomes.
        </p>
      </div>
       
    },
    {
      key: "retail",
      title: "Retail",
      description:<div>
      <h4
      className="primary-text-color"
      style={{
        fontSize:24,
        fontWeight:700
      }}
      >Enhanced Inventory and Demand Forecasting</h4>
      <p
      style={{
        fontSize:18,
        fontWeight:400
      }}
      >
     Use AI to optimize inventory levels, reduce waste, and predict demand trends to ensure customer satisfaction.
          </p>
    </div>
    },
    {
      key: "finance",
      title: "Financial Services",
      description:<div>
      <h4
      className="primary-text-color"
      style={{
        fontSize:24,
        fontWeight:700
      }}
      >Advanced Fraud Detection</h4>
      <p
      style={{
        fontSize:18,
        fontWeight:400
      }}
      >
      Leverage real-time data monitoring to detect fraud, ensure security, and improve trust with AI-powered precision.
      </p>
    </div>
    },
    {
      key: "manufacturing",
      title: "Manufacturing",
      description:<div>
      <h4
      className="primary-text-color"
      style={{
        fontSize:24,
        fontWeight:700
      }}
      >Predictive Maintenance for Equipment</h4>
      <p
      style={{
        fontSize:18,
        fontWeight:400
      }}
      >
      Predict equipment failures before they happen, reducing downtime and saving costs through AI-powered monitoring and maintenance planning.
      </p>
    </div>
    },
  ];

  return (
    <div id={id} 
     style={{
      backgroundColor:'#f7f7f7',
      paddingBottom:10
     }}
    >
      <TabComponent
        title="Industry-Specific AI Use Cases"
        subtitle="Explore how ZCLAP's AI solutions are driving change across various sectors."
        tabsData={industryTabs}
       
      />
      <Container
        className="text-center py-9"
        style={{
          backgroundColor: "#F8F8F8",
          color: "#000",
          fontSize:'1.8rem',
          maxWidth:1024
        }}
      >
        <p>
          Join the future of business with ZCLAP’s AI solutions.{" "}
          <span
            style={{
              color: "#FF6E3B",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Book your free AI consultation today
          </span>{" "}
          to learn how we can transform your business with intelligent solutions
          designed to meet your unique needs.
        </p>
      </Container>
    </div>
  );
};
