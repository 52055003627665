import { Footer } from "../footer";
import { Header } from "../header";

export  const  Layout=({ children }) =>{
    return (
      <div
        style={{
          height:'100%'
        }}
      >
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    );
  }