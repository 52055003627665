import React from "react";
import { Layout } from "../../components/layout";
import { DataManagementServices } from "./data-management-services";
import { PartnerLogos } from "../home/partner-logos";
import { AIServices } from "../home/ai-services";
import { CallToAction } from "../home/call-to-action";
import { AboutSection } from "../home/about-section";
import { TabComponent } from "../../components/tabs-component";
import { IndustryUseCases } from "../home/Industry-Specific";
import { DataManagementSolutions } from "./data-management-solutions";
import "./data-management.scss";
import { ContactForm } from "../home/contact-form";
import { Button } from "react-bootstrap";
export const DataManagement = () => {
  const ManagementServicesList = [
    {
      key: "ai_expertise",
      title: "Expertise in AI Solutions",
      description:
      <p
      style={{
        fontSize:18,
        fontWeight:400
      }}
      >
    Our team’s deep experience in AI solutions ensures precise, scalable, and future-proofed data systems.
      </p>,
      imageSrc: "/assets/ai-values/values-tab.png",
    },
    {
      key: "data_compliance",
      title: "Enhanced Data Compliance and Security",
      description:
      <p
      style={{
        fontSize:18,
        fontWeight:400
      }}
      >
   We prioritize data governance to keep your data safe and compliant in an ever-evolving regulatory environment.
      </p>,
      imageSrc: "/assets/ai-values/values-tab.png",
    },

    {
      key: "operational_efficiency",
      title: "Increased Operational Efficiency",
      description:
      <p
      style={{
        fontSize:18,
        fontWeight:400
      }}
      >
  Streamlined data processes allow your team to focus on strategic growth rather than data errors and inconsistencies.
      </p>,
      imageSrc: "/assets/ai-values/values-tab.png",
    },
  ];

  return (
    <Layout>
      <DataManagementServices />
      <PartnerLogos />
      <CallToAction
        title="Ready to Begin? Get a Free Data Management Consultation Today!"
        description="Unlock the potential of Data for your business. Reach out for a complimentary consultation to explore how ZCLAP’s tailored Data Management Services can meet your unique needs and goals."
        buttonText="Get in touch"
        onButtonClick={() => {}}
      />
      <AIServices
        noBackgroundImg={false}
        extra={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 100,
              marginTop: 10,
            }}
          >
            <Button
              style={{
                background:
                  "linear-gradient(93.01deg, #F06A30 11.72%, #8A3D1C 118.94%)",
                border: "none",
                borderRadius: 4,
              }}
            >
              Schedule a free consultation
            </Button>
          </div>
        }
      />
      <AboutSection />
      <CallToAction
        title="Start Your Data Journey with a Free Consultation"
        description="We’re here to help you harness your data’s full potential. Begin your journey with a complimentary consultation, where we’ll explore the tailored solutions ZCLAP can offer for your unique data challenges."
        buttonText="Schedule a free consultation today!"
        btnProps={{
          href: "#contact",
        }}
        onButtonClick={() => {}}
      />

      <TabComponent
        title="Why Choose ZCLAP’s Data Management Services?"
        subtitle="ZCLAP brings unparalleled value to your data management journey with:"
        tabsData={ManagementServicesList}
      />
      <DataManagementSolutions />
      <IndustryUseCases id={"industry"} />
      <ContactForm
        title="We’d love to hear from you anytime"
        description="Reach out to us with any inquiries or project details. We are here to help!"
        buttonText="Submit"
      />
    </Layout>
  );
};
