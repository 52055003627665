import React from "react";
import { Navbar, Nav, NavDropdown, Button, Container } from "react-bootstrap";
import { SocialMedia } from "./social-media";

export const Header = () => {
  return (
    <div 
      style={{
        // position:'sticky',
        // top:0,
        // left:0,
        // right:0,
        // zIndex:999
      }}
    >
      <SocialMedia />
      <Navbar
        bg="black"
        variant="dark"
        expand="lg"
        // className="p-3"
        style={{
          boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)",
          paddingTop:0,
          paddingBottom:0
        }}
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src="/assets/logo.png"
              alt="ZCLAP Logo"
              style={{ height: "40px", marginRight: "10px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="AI" id="basic-nav-dropdown">
                <NavDropdown.Item href="/">Our Services</NavDropdown.Item>
                {/* <NavDropdown.Item href="/#success">
                  Success Stories
                </NavDropdown.Item> */}
                <NavDropdown.Item href="/#about">About Us</NavDropdown.Item>
                <NavDropdown.Item href="/#values">Values</NavDropdown.Item>
                <NavDropdown.Item href="/#ourTechnologies">
                  Our Technologies
                </NavDropdown.Item>
                <NavDropdown.Item href="/#engagementModel">
                  Engagement Model
                </NavDropdown.Item>
                <NavDropdown.Item href="/#ai-services">
                  AI Use Cases
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Data Management" id="basic-nav-dropdown">
                <NavDropdown.Item href="/data-management">
                  Our Services
                </NavDropdown.Item>
                <NavDropdown.Item href="/data-management#services">
                Services
                </NavDropdown.Item>
                <NavDropdown.Item href="/data-management#about">
                  About Us
                </NavDropdown.Item>
                <NavDropdown.Item href="/data-management#ai-services">
                  AI Use Cases
                </NavDropdown.Item>
                <NavDropdown.Item href="/data-management#solutions">
                Solutions
                </NavDropdown.Item>
                <NavDropdown.Item href="/data-management#industry">
                Industry
                </NavDropdown.Item>
            
              </NavDropdown>
              <Nav.Link href="">Blogs</Nav.Link>
              <Nav.Link href="">Case Studies</Nav.Link>
              <Nav.Link href="#contact">Contact Us</Nav.Link>
            </Nav>
            <Button
              // variant="default"
              size="sm"
              style={{
                borderRadius: 95,
                width: 126,
              }}
              href="#contact"
            >
              Get in touch
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
