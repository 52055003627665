import React from "react";
import { TabComponent } from "../../components/tabs-component";
import { Col, Row } from "react-bootstrap";

export const EngagementModels = () => {
  const engagementTabs = [
    {
      key: "useCase1",
      title: "Use Case 1",
      description: (
        <Row style={{ minHeight:400 }} >
          <Col xs={12} sm={6} md={8}>
          <h3 className="text-black mb-4">
              AWS Modern Analytics Platform in Healthcare Readmission Prediction
            </h3>
            <p
              style={{
                color: " #333333",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              In the complex landscape of U.S. healthcare, hospital re
              admissions represent a critical challenge that impacts patient
              outcomes and healthcare economics. Annually, approximately 3.8
              million Medicare patients experience hospital re admissions,
              generating $26 billion in unnecessary healthcare expenses. Our AI
              consulting services leveraged the AWS Modern Analytics Platform to
              develop a trans-formative predictive analytics solution for
              healthcare providers. By utilizing AWS comprehensive data
              analytics tools, we created a robust data ecosystem that
              integrates, analyzes, and visualizes complex healthcare datasets.
              The platform enables seamless data collection from electronic
              health records, patient demographics, and treatment histories,
              allowing for advanced predictive modeling that identifies patients
              at elevated risk of re admission. This data-driven approach
              empowers healthcare organizations to develop proactive
              intervention strategies, personalized care plans, and reduce re
              admission rates while optimizing healthcare resource allocation.
            </p>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={4}
            className="d-flex justify-content-center"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <UseCaseCard>Use Case 1</UseCaseCard>
          </Col>
        </Row>
      ),
    },
    {
      key: "useCase2",
      title: "Use Case 2",
      description: (
        <Row style={{ minHeight:400 }} >
          <Col xs={12} sm={6} md={8}>
            <h3 className="text-black mb-4">
              AWS Modern Analytics Platform for Nonprofit Donation Propensity
              Prediction
            </h3>
            <p
              style={{
                color: " #333333",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Nonprofit organizations face significant challenges in sustaining
              and growing their funding through donor contributions, making
              accurate donor targeting a critical strategic imperative. By
              implementing the AWS Modern Analytics Platform, we provide
              nonprofits with a sophisticated predictive analytics solution that
              transforms donor engagement strategies. Leveraging advanced data
              integration tools, our solution aggregates diverse data sources
              including past donation history, demographic information,
              engagement metrics, and interaction records. The platform's
              powerful machine learning capabilities enable the creation of
              nuanced predictive models that assess individual donor propensity,
              identifying potential high-value contributors and their likelihood
              of making future donations. This data-driven approach allows
              nonprofits to develop personalized outreach strategies, optimize
              fundraising efforts, and allocate resources more effectively,
              improving donor retention and maximizing fundraising potential.
            </p>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={4}
            className="d-flex justify-content-center"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <UseCaseCard>Use Case 2</UseCaseCard>
          </Col>
        </Row>
      ),
    },
    {
      key: "useCase3",
      title: "Use Case 3",
      description: (
        <Row style={{ minHeight:400 }}>
          <Col xs={12} sm={6} md={8}>
            <h3 className="text-black mb-4">
              AWS AI Platform for Intelligent Document Processing and
              Information Retrieval
            </h3>
            <p
              style={{
                color: " #333333",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              In today's digital landscape, organizations struggle with
              information overload, facing challenges in efficiently processing
              and extracting insights from vast repositories of unstructured
              documents, PDF, and legacy records. Traditional manual and
              OCR-based approaches create significant bottlenecks in
              organizational agility and scalability. By leveraging the AWS AI
              Platform, our solution revolutionizes information retrieval
              through an advanced technological ecosystem that combines
              innovative AI technologies. Utilizing AWS for document extraction,
              AWS for generative AI capabilities, vector databases, and for
              semantic search, we create a powerful intelligent document
              processing system. This innovative approach enables organizations
              to automatically convert unstructured data into actionable
              insights, dramatically reducing manual effort, enhancing
              information accessibility, and transforming how businesses
              interact with their document repositories. The solution provides
              intelligent summarization, semantic search, and conversational
              interactions with complex document sets, enabling unprecedented
              efficiency and knowledge management.
            </p>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={4}
            className="d-flex justify-content-center"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <UseCaseCard>Use Case 3</UseCaseCard>
          </Col>
        </Row>
      ),
    },
    {
      key: "useCase4",
      title: "Use Case 4",
      description: (
        <Row style={{ minHeight:400 }}>
          <Col xs={12} sm={6} md={8}>
            <h3 className="text-black mb-4">
              AWS Modern Analytics Platform in Healthcare Readmission Prediction
            </h3>
            <p
              style={{
                color: " #333333",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              In the complex landscape of U.S. healthcare, hospital re
              admissions represent a critical challenge that impacts patient
              outcomes and healthcare economics. Annually, approximately 3.8
              million Medicare patients experience hospital re admissions,
              generating $26 billion in unnecessary healthcare expenses. Our AI
              consulting services leveraged the AWS Modern Analytics Platform to
              develop a trans-formative predictive analytics solution for
              healthcare providers. By utilizing AWS's comprehensive data
              analytics tools, we created a robust data ecosystem that
              integrates, analyses, and visualizes complex healthcare datasets.
              The platform enables seamless data collection from electronic
              health records, patient demographics, and treatment histories,
              allowing for advanced predictive modeling that identifies patients
              at elevated risk of readmission. This data-driven approach
              empowers healthcare organizations to develop proactive
              intervention strategies, personalized care plans, and reduce
              readmission rates while optimizing healthcare resource allocation.
            </p>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={4}
            className="d-flex justify-content-center"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <UseCaseCard>Use Case 4</UseCaseCard>
          </Col>
        </Row>
      ),
    },
    {
      key: "useCase5",
      title: "Use Case 5",
      description: (
        <Row style={{ minHeight:400 }}>
          <Col xs={12} sm={6} md={8}>
            <h3 className="text-black mb-4">
              AWS AI Platform for Manufacturing Process Automation
            </h3>
            <p
              style={{
                color: " #333333",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Manufacturing organizations face critical challenges in
              scalability and efficiency, particularly in manual processes that
              consume considerable time and resources. From converting building
              plant requirements to conducting quality checks, traditional
              approaches limit operational agility and precision. Our AWS AI
              Platform solution transforms these challenges by integrating
              advanced computer vision, generative AI, and intelligent
              automation technologies. By leveraging AWS's comprehensive AI
              capabilities, we develop intelligent systems that can
              automatically read, interpret, and interact with complex
              manufacturing documents, extract critical information, and
              generate actionable insights. The solution enables automated
              quality inspection, streamlines raw material requirement planning,
              and provides real-time analytical capabilities that significantly
              reduce human error, enhance operational efficiency, and create a
              more adaptive and responsive manufacturing ecosystem. Through
              intelligent document processing and AI-driven insights,
              manufacturers can achieve unprecedented levels of operational
              optimization and strategic decision-making.
            </p>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={4}
            className="d-flex justify-content-center"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <UseCaseCard>Use Case 5</UseCaseCard>
          </Col>
        </Row>
      ),
    },
    {
      key: "useCase6",
      title: "Use Case 6",
      description: (
        <Row style={{ minHeight:400 }}>
          <Col xs={12} sm={6} md={8}>
            <h3 className="text-black mb-4">
              Computer Vision and NLP-Powered Digital Twin for Banking
              Transformation
            </h3>
            <p
              style={{
                color: " #333333",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Financial institutions face complex challenges in managing
              operational efficiency, customer interactions, and risk
              management. Our innovative solution creates a comprehensive
              digital twin using computer vision and natural language processing
              technologies. By integrating real-time data from branch
              interactions, transaction records, and communication channels, the
              digital twin provides banks with an unprecedented, dynamic model
              of their entire operational ecosystem. Computer vision analyses
              physical branch operations, while NLP decodes customer
              communications and transactional interactions. This approach
              enables advanced insights into operational bottlenecks, customer
              experience optimization, fraud detection, and strategic
              decision-making, transforming traditional banking models into
              intelligent, predictive, and adaptive systems.
            </p>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={4}
            className="d-flex justify-content-center"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <UseCaseCard>Use Case 6</UseCaseCard>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <TabComponent
      placement="top"
      title="Our AI Solutions Engagement Models"
      tabsData={engagementTabs}
      styleTitle={{
        textAlign: "left",
        marginBottom: 15,
      }}
    />
  );
};

const UseCaseCard = ({ children }) => {
  return (
    <div>
      <svg
        width="355"
        height="252"
        viewBox="0 0 355 252"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="229.097"
          height="229.097"
          fill="#FF9E5C"
          fill-opacity="0.4"
        />
        <rect x="29" y="23" width="326" height="229" fill="#FF9E5C" />
        <text
          x="50%"
          y="50%"
          text-anchor="middle"
          dominant-baseline="middle"
          font-family="Arial, sans-serif"
          font-size="40"
          fill="white"
          font-weight='700'
        >
          {children}
        </text>
        <defs>
          <linearGradient
            id="paint0_linear_0_1804"
            x1="69.2208"
            y1="23"
            x2="435.071"
            y2="50.5249"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF9E5C" />
            <stop offset="1" stop-color="#FF9E5C" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default UseCaseCard;
