import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SocialMediaLinks } from "../footer";

export const SocialMedia = () => {
  return (
    <div style={{ backgroundColor: "rgba(25, 25, 25, 1)", height: 50 }}>
      <Container
        style={{
          height: "100%",
        }}
      >
        <Row className="align-items-center justify-content-center"
            style={{ 
                display: 'flex',
                height: '100%'
                

            }}
        >
        <Col  style={{ display:'flex',gap:20 }}  >
              {/* Social Media Icons */}
        <SocialMediaLinks/>
            </Col>
          {/* Email Icon */}
          <Col xs="auto">
            <a href="mailto:info@zclap.com" style={{ color:'#fff' }} >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="#FFFFFF"
              >
                <path d="M12 13.065L.345 4.148C.746 3.512 1.468 3 2.3 3h19.4c.832 0 1.554.512 1.955 1.148L12 13.065zM24 5.882v12.236c0 .844-.676 1.529-1.55 1.529H1.55C.676 19.647 0 18.962 0 18.118V5.882l12 8.553 12-8.553z" />
              </svg>
            <span style={{ marginLeft:10  }} >mailto:info@zclap.com</span>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
