import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const Footer = () => {
  return (
    <footer className="bg-black text-white py-4"
    style={{
      backgroundColor:' #000000',
      paddingBottom: '0px !important'
    }}
    >
      <Container  >
        <Row>
          <Col md={4}>
            <img
              src="/assets/logo.png"
              alt="ZCLAP Logo"
              style={{ height: "40px", marginBottom: "10px" }}
            />
            <p>Social Links</p>
          <SocialMediaLinks/>
          </Col>
          <Col md={4}>
            <p>Quick Links</p>
            <ul className="list-unstyled">
              <li>
                <a href="/#about" className="text-white">
                  About Us
                </a>
              </li>
              <li>
                <a href="/" className="text-white">
                  AI Services
                </a>
              </li>
              <li>
                <a href="/data-management" className="text-white">
                  Data Management Services
                </a>
              </li>
              <li>
                <a href="#" className="text-white">
                  Blogs
                </a>
              </li>
              <li>
                <a href="#" className="text-white">
                  Case Studies
                </a>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <p>Contact With Us</p>
            <ul className="list-unstyled">
              {/* <li>+1 1234567890</li> */}
              <li>info@zclap.com</li>
              {/* <li>Address</li> */}
            </ul>
          </Col>
        </Row>
        <div 
        style={{
          marginTop:10,
          height: 1,
          backgroundImage: `repeating-linear-gradient(
            to right,
            #FFFFFF,
            #FFFFFF 4px, /* Dot width */
            transparent 2px,
            transparent 10px /* Gap width */
          )`
        }}
        ></div>
        <div className="text-center"
        style={{
          height:70,
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
        }}
        >
          <p style={{ marginBottom:0 }} >Copyright {new Date().getFullYear()} © ZCLAP Inc.</p>
        </div>
      </Container>
    </footer>
  );
};


export const SocialMediaLinks=()=>{
  return   <div className="d-flex gap-2 center">
  {/* <a href="#" className="text-white">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="#1DA1F2"
  >
    <path d="M24 4.557a9.831 9.831 0 01-2.828.775 4.933 4.933 0 002.165-2.724 9.864 9.864 0 01-3.127 1.195 4.916 4.916 0 00-8.384 4.482 13.94 13.94 0 01-10.124-5.14 4.916 4.916 0 001.523 6.57A4.904 4.904 0 01.96 8.794v.062a4.917 4.917 0 003.946 4.827 4.996 4.996 0 01-2.212.084 4.92 4.92 0 004.593 3.417A9.867 9.867 0 010 21.539a13.947 13.947 0 007.548 2.212c9.056 0 14.002-7.514 14.002-14.028 0-.213-.005-.426-.014-.637A10.012 10.012 0 0024 4.557z" />
  </svg>
  </a> */}
  <a href="https://www.linkedin.com/company/zclap-inc/" target="_blank" className="text-white">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="#0077B5"
  >
    <path d="M22.23 0H1.77C.79 0 0 .774 0 1.729v20.542C0 23.226.79 24 1.77 24h20.46c.98 0 1.77-.774 1.77-1.729V1.729C24 .774 23.21 0 22.23 0zM7.12 20.452H3.56V9.047h3.56v11.405zM5.34 7.751c-1.14 0-2.06-.932-2.06-2.075s.92-2.076 2.06-2.076c1.14 0 2.06.933 2.06 2.076s-.92 2.075-2.06 2.075zM20.45 20.452h-3.56v-5.918c0-1.411-.028-3.222-1.962-3.222-1.963 0-2.262 1.532-2.262 3.116v6.024h-3.56V9.047h3.419v1.55h.048c.477-.902 1.638-1.85 3.372-1.85 3.6 0 4.267 2.368 4.267 5.452v6.253z" />
  </svg>
  </a>
  {/* <a href="#" className="text-white">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="#1877F2"
  >
    <path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.406.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.894-4.787 4.659-4.787 1.325 0 2.463.099 2.794.143v3.24h-1.918c-1.504 0-1.796.715-1.796 1.763v2.311h3.587l-.467 3.622h-3.12V24h6.116c.73 0 1.324-.593 1.324-1.324V1.325C24 .593 23.406 0 22.675 0z" />
  </svg>
  </a>
  <a href="#"  className="text-white">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="#FF0000"
  >
    <path d="M19.615 3.184A3.004 3.004 0 0017.9 2.887C15.938 2.701 12.015 2.701 12.015 2.701h-.03s-3.923 0-5.885.186a3.004 3.004 0 00-1.715.297 3.18 3.18 0 00-1.12 1.119 6.067 6.067 0 00-.66 2.347 38.4 38.4 0 00-.186 4.188v1.014c.01 1.415.063 2.83.186 4.244.08 1.008.335 1.812.66 2.347a3.18 3.18 0 001.12 1.12 3.004 3.004 0 001.715.296c1.962.185 5.885.185 5.885.185h.03s3.923 0 5.885-.186a3.004 3.004 0 001.715-.296 3.18 3.18 0 001.12-1.12c.325-.535.58-1.34.66-2.347.123-1.414.176-2.829.186-4.244v-1.014c-.01-1.415-.063-2.83-.186-4.244a6.067 6.067 0 00-.66-2.347 3.18 3.18 0 00-1.12-1.12zM9.93 15.175V8.824l6.151 3.176-6.15 3.175z" />
  </svg>
  </a> */}
</div>
}