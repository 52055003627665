import React from "react";
import {  Col, Container, Row } from "react-bootstrap";

export const AboutSection = () => {
  return (
      <Container style={{ minHeight: 683,justifyContent:'center',alignItems:'center',display:'flex' }} id='about' >
        <Row className="g-4">
          <Col xs={12} sm={5} md={4} className="d-flex justify-content-center">
              <div 
                style={{
                  maxWidth:521,
                  maxHeight:537
                }}
              >
              <img
              src="/assets/about-section-left.png"
              alt="About ZCLAP"
              style={{
                width:'100%',
                height:'100%'
              }}
            />
              </div>
          </Col>
          <Col
            xs={12}
            sm={7}
            md={8}
            className="d-flex justify-content-center"
            style={{
              display: "flex",
              justifyItems: "center",
              flexDirection: "column",
              
            }}
          >
            <h2
              className="fw-bold primary-text-color title-text">
            About ZCLAP: Your Partner in Data Driven Transformation
            </h2>
            <p
        className=" text-lg text-gray-300 mb-12 font-family"
          style={{
            fontSize:20,
            color:'rgba(110, 110, 110, 1)'
          }}
        >
        Innovative Solutions, Personalized Approach, Proven Results
        </p>
            <p
              className="text-gray-600 mb-6 font-family"
              style={{
                fontSize: 24,
                color:'rgba(110, 110, 110, 1)'
              }}
            >
             ZCLAP is a boutique consulting firm dedicated to providing customized AI and data-driven solutions for businesses across industries. Our expert team combines deep industry knowledge with a passion for innovation, bringing bespoke AI strategies that empower your organization to thrive in a data-first world.
            </p>
          </Col>
        </Row>
      </Container>
  );
};
