import React from "react";

export const PartnerLogos = () => {
    const logos = [
        "/assets/partner/partner-1.png",
        "/assets/partner/partner-2.png",
        "/assets/partner/partner-4.png",
        "/assets/partner/partner-5.png",
        "/assets/partner/partner-6.png",
        "/assets/partner/partner-3.png",
    ];

    // Create 4 sets of logos for smoother infinite scroll
    const repeatedLogos = [...logos, ...logos, ...logos, ...logos];

    return (
        <div className="partner-logos">
            <div className="logo-slider">
                <div className="logo-track">
                    {repeatedLogos.map((logo, index) => (
                        <div key={index} className="logo-wrapper">
                            <img
                                src={logo}
                                alt={`Partner Logo ${(index % logos.length) + 1}`}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PartnerLogos;