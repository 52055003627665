import React from "react";
import { Container, Button } from "react-bootstrap";

export const CallToAction = ({
  title,
  description,
  buttonText,
  onButtonClick,
  btnProps={}
}) => {
  return (
    <div
      style={{
        background: "linear-gradient(83.33deg, #F7F7F7 -2.34%, #FFECE0 100%)",
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        width: "100%",
        minHeight: 400,
      }}
    >
      <Container
        style={{
          backgroundImage:
            "url('/assets/data-management/call-to-action.png')",
          backgroundSize: "cover",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          padding: 30,
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 10,
          maxWidth: 1141,
        }}
      >
        <h2
          style={{
            fontSize: "28px",
            fontWeight: "bold",
            marginBottom: "15px",
            textAlign: "center",
          }}
          className="primary-text-color"
        >
          {title}
        </h2>
        <p
          className="font-family"
          style={{
            fontSize: "16px",
            color: "#cccccc",
            marginBottom: "25px",
            maxWidth: "70%",
          }}
        >
          {description}
        </p>
        <Button
          // variant="warning"
          style={{
            background:
              "linear-gradient(93.01deg, #F06A30 11.72%, #8A3D1C 118.94%)",
            border: "none",
            borderRadius: 4,
            minWidth: 220,
          }}
          onClick={onButtonClick}
          {...btnProps}
        >
          {buttonText}

          <span style={{ marginLeft: 10 }}>
            <svg
              width="18"
              height="12"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.0376 2.0448L9.86865 1.46306C10.2205 1.21674 10.7895 1.21674 11.1377 1.46306L18.415 6.55458C18.7669 6.8009 18.7669 7.19921 18.415 7.44291L11.1377 12.5371C10.7858 12.7834 10.2168 12.7834 9.86865 12.5371L9.0376 11.9553C8.68197 11.7064 8.68945 11.3002 9.05257 11.0565L13.5635 8.04824H2.80469C2.3068 8.04824 1.90625 7.76785 1.90625 7.41933V6.58079C1.90625 6.23227 2.3068 5.95188 2.80469 5.95188H13.5635L9.05257 2.94361C8.68571 2.69991 8.67822 2.29374 9.0376 2.0448Z"
                fill="white"
              />
            </svg>
          </span>
        </Button>
      </Container>
    </div>
  );
};
