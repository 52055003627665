import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

export const DataManagementSolutions = () => {
  const solutions = [
    {
      id: 1,
      title: "Assessment And Strategy",
      description:
        "We begin with a comprehensive analysis to create a customized data management strategy.",
    },
    {
      id: 2,
      title: "Implementation And Integration",
      description:
        "Our team implements solutions seamlessly within your existing systems, minimizing disruptions.",
    },
    {
      id: 3,
      title: "Ongoing Support And Optimization",
      description:
        "Continuous monitoring and optimization ensure that your data management systems remain effective and aligned with your evolving needs.",
    },
  ];

  return (
    <Container className="py-5 data-management-solutions-container" id='solutions'>
      {/* Section Title */}
      <div className="mb-4">
        <h2 className="primary-text-color fw-bold title-text">Tailored Data Management Solutions</h2>
        <p className="text-lg text-gray-300 mb-12 font-family"
            style={{
              fontSize:24
            }}>
          Our engagement model is designed to align with your specific business goals and technical needs:
        </p>
      </div>

      {/* Cards Section */}
      <Row style={{ display:'flex',justifyContent:'center',alignItems:'center',width:'100%' }} >
        {solutions.map((solution) => (
          <Col key={solution.id} md={4} className="mb-3 text-center" style={{ display:'flex',justifyContent:'center',alignItems:'center'}}  >
            <Card className="custom-card">
              <Card.Body>
                <h5 className="card-title">{solution.title}</h5>
                <p className="card-description">{solution.description}</p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
