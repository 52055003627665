import React from "react";
import { Layout } from "../../components/layout";
import { HeroSection } from "./hero-section";
import { AIServices } from "./ai-services";
import { PartnerLogos } from "./partner-logos";
import { AboutSection } from "./about-section";
import { ValuesTab } from "./values-tab";
import { IndustryUseCases } from "./Industry-Specific";
import { EngagementModels } from "./engagement-models";
import { Technologies } from "./technologies";
import { CallToAction } from "./call-to-action";
import { ContactForm } from "./contact-form";

import '../data-management/data-management.scss'
export const Home=()=> {
    return (
      <Layout>
        <HeroSection/>
        <PartnerLogos/>
        <AIServices/>
        <AboutSection/>
        <CallToAction
        title="Ready to Begin? Get a Free AI Consultation Today!"
        description="Unlock the potential of AI for your business. Reach out for a no-obligation consultation to explore how tailored AI solutions can meet your unique needs and goals."
        buttonText="Get in touch"
        btnProps={{
          href:"#contact"
        }}
        onButtonClick={()=>{
          
        }}
      />
        <ValuesTab/>
        <Technologies/>
        <EngagementModels/>
        <IndustryUseCases/>
        <ContactForm
                title="We’d love to hear from you anytime"
                description="Reach out to us with any inquiries or project details. We are here to help!"
                buttonText="Submit"
              />
      </Layout>
    );
  }